#about {
  /*  this refers to the section in home.js */
  background-color: rgb(41, 48, 46);
  color: #fff;
  
}

.white {
  color: #fff;
}

.about-section-container {
  display: flex;
  padding-block: 5%;
}

.about-section-photo {
  max-width: 75%;
}

@media (max-width: 575px) {
  .about-section-container {
    flex-direction: column;
  }

  .about-section-photo {
    max-width: 100%;
  }
}