.botm-line.yellow-line {
  margin: 0;
  margin-bottom: 20px;
}

.section-title.lg-line {
  margin-bottom: .5rem;
}

.title {
  display: grid;
  justify-content: center;
}

.bios {
  display: flex;
  justify-content: center;
}