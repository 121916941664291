/* EventCalendar.css */

/* Style the container of the calendar */
.event-calendar-container {
  max-width: 400px; /* Set max-width as needed */
  margin: 0 auto; /* Center the calendar horizontally */
  width: max-content;
}

/* Style the calendar itself */
.react-calendar {
  width: 100%;
  max-width: 400px; /* Set max-width as needed */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Style the calendar navigation buttons */
.react-calendar__navigation button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

/* Style the calendar navigation button text */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f0f0f0;
}

/* Style the calendar navigation button text */
.react-calendar__navigation__label {
  font-weight: bold;
}

/* Style the calendar day tile */
.react-calendar__tile {
  text-align: center;
}

/* Style the calendar day tile for weekends */
.react-calendar__month-view__days__day--weekend {
  color: black
}

.highlighted-date {
  background-image: radial-gradient(closest-side, rgb(44 26 244), rgb(44 26 244) 30%, transparent 30%);
  background-position: bottom;
  background-size: 32% 45%; /* Adjust the size of the circle here */
  background-repeat: no-repeat;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: radial-gradient(circle, transparent 30%, transparent 35%, rgba(103, 80, 164, 0.7) 35%, rgba(103, 80, 164, 0.7) 40%, transparent 40%);
  background-position: center;
  background-size: 100% 100%; /* Adjust the size of the circle based on your UI needs */
  background-repeat: no-repeat;
}

.react-calendar__tile--active:enabled:hover {
  background: radial-gradient(circle, transparent 30%, transparent 35%, rgba(103, 80, 164, 0.7) 35%, rgba(103, 80, 164, 0.7) 40%, transparent 40%);
  background-position: center;
  background-size: 100% 100%; /* Adjust the size of the circle based on your UI needs */
  background-repeat: no-repeat;
}

.react-calendar__tile--active {
  background: radial-gradient(circle, transparent 30%, transparent 35%, rgba(103, 80, 164, 0.7) 35%, rgba(103, 80, 164, 0.7) 40%, transparent 40%);
  background-position: center;
  background-size: 100% 100%; /* Adjust the size of the circle based on your UI needs */
  background-repeat: no-repeat;
  color: black;
}

/* Hide days outside the current month */
.react-calendar__month-view__days__day--neighboringMonth {
  color: white;
}

.react-calendar__tile--now {
  background: radial-gradient(circle, #e0e0e0, #e0e0e0 30%, transparent 30%);
  background-position: center;
  background-size: 155% 170%; /* Adjust the size of the circle here */
  background-repeat: no-repeat;
  color: #333;               
  font-weight: bold;         
}

.react-calendar__tile:enabled:hover {
  background: radial-gradient(circle, transparent 30%, transparent 35%, #e6e6e6 35%, #e6e6e6 40%, transparent 40%);
  background-position: center;
  background-size: 100% 100%; /* Adjust the size of the circle based on your UI needs */
  background-repeat: no-repeat;
}

.react-calendar__month-view__weekdays {
  text-decoration: none; /* Remove underline from weekdays */
}

.react-calendar__month-view__weekdays__weekday abbr {
  border-bottom: none; /* Remove underline from the weekday abbreviation */
}

.react-calendar__month-view__weekdays__weekday {
  background-color: #e9927a;
  text-decoration: none;
  border: none;
  text-transform: math-auto;
  padding: 1px;
}

abbr {
  display: contents;
}

/* xs devices */
@media (max-width: 575px) {
  .event-calendar-container {
    display: flex;
    padding-inline: 5%;
    width: auto;
  }
}