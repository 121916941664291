

  .header{
    height: 80px;
    background-color: goldenrod;
    border-radius: 3px;
    padding: 6px;
}

.header .logo{
    font-size: 30px;
    font-weight: bold;
    color: white;
}

.labels{
  display: flex;
  align-items: center;
}

.fa-solid{
  color: goldenrod;
}

.fa-trash:hover{
  cursor: pointer;
  color: #333;
}

.payTab {
  background-color: #006aff29;
}

#cash-app-tab{
  background-color: #0a0a0b29;
}

#card-tab:hover{
  background-color: #006aff66;
}

#cash-app-tab:hover{
  background-color: #10ba087d;
}

.modal-body .payment-container{
  margin-top: 45px;
}

.fa-brands{
  margin-inline: 3px;
}

h3{
  margin-block: 17px;
}

.toast-success{
  background-color: green;
}

.toast-error{
  background-color: red;
}

.modal-header{
  display: grid;
}

.modal-header input{
  margin-block: 2px;
}

.total {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin-top: 10px;
	padding-top: 19px;
	font-weight: 600;
	line-height: 1;
  display: flex;
  justify-content: space-between;
}

.modalHeader {
  background-color: aliceblue;
  padding: var(--bs-modal-padding);
}

.modalBody {
  padding: var(--bs-modal-padding);
}