.item-name {
  font-weight: 600;
  font-size: 0.9em;
}

.price {
  float: inline-end;
  font-weight: 600;
  font-size: 0.9em;
}

.item-description {
  font-size:0.8em;
	opacity:0.6;
}

.title {
  font-size: 1em;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	margin-bottom: 0.8em;
	font-weight: 600;
	padding-bottom: 8px;
}