h1 h4 {
  margin-bottom: 0;
}

.event-detail-label {
  color: #e9927a;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

svg {
  margin-inline: 2px;
}
