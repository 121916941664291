#banner {
  background-color: RGBA(263, 106, 74, 0.78);
  position: relative;
}

.banner-info {
  padding-top: 5%;
}

.banner-logo img {
  display: inline-block;
}

.banner-text {
  color: #fff;
}

.banner-text h1 {
  font-family: 'Candal', sans-serif;
  font-size: 35px;
  text-transform: uppercase;
  padding-bottom: 15px;
}

.btn-appoint, .btn-appoint:hover, .btn-appoint:focus {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 12px;
  background-color: rgba(12, 184, 182, 0.91);
  border-radius: 3px;
  color: #fff;
}

.overlay-detail a i {
  text-align: center;
  position: absolute;
  bottom: 25px;
  font-size: 38px;
  color: #fff;
  margin: 0 auto;
}

.text-primary i {
  padding-top: 8px;
  display: inline-block;
}

/* Media Queries */
@media (max-width: 604px) {
  .bg-color {
    background-size: contain;
  }

  .banner-text h1 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }

  .btn-appoint {
    font-size: 10px; /* Adjust button font size for smaller screens */
    padding: 8px 16px; /* Adjust button padding for smaller screens */
  }
}
