#events {
  padding-block: 5%;
}

.event-content {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.event-content h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.event-content h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
  margin-block: unset;
}

.event-content-container {
  display: grid;
  margin: 0 auto;
  justify-content: center;
}

.event-content p {
  font-size: 1rem;
  line-height: 1.5;
}

.all-events-link {
  color: #007bff;
  /* Bootstrap primary color */
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.all-events-link:hover,
.all-events-link:focus {
  text-decoration: underline;
  color: #0056b3;
  /* A darker shade for hover state */
}

.heading {
  display: grid;
  justify-content: center;
}

.event-section {
  display: flex;
  justify-content: center;
}

@media (max-width: 732px) {
  .event-section {
    display: unset;
  }
}