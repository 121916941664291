#contact {
  background: #fb4f2c;
  padding-block: 5%;
}

#contact p {
  color: beige;
}

.space {
  margin-top: 40px;
}

.btn-form,
.btn-form:hover,
.btn-form:focus {
  background-color: #bb391ffa;
  color: #fff;
  border-radius: 0px;
  padding: 10px 20px;
}

.br-radius-zero {
  border-radius: 0px;
}

.form-control {
  height: 40px;
}

.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#sendmessage {
  color: green;
  border: 1px solid green;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
  display: block;
}